import React from 'react'
import '../App.css'
import Footer_image from '../img/foot1.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faInstagram,
    faWhatsapp,
    faLinkedin,
  } from "@fortawesome/free-brands-svg-icons";
import mapImage from '../img/add_map.png'


const Footer = ()=>{

    return(
            <footer id="footer" class="footer-with-credit" >

                            <div class="main-footer" style={{ backgroundImage: `url(${Footer_image})` }}>
                                <div class="whatEFB"> 
                                <div>
                                    <h1 class ="Hone">Excellence Financing</h1>
                                    <h1 class ="Hone">Broker</h1>
                                        <p class="para">
                                        EXCELLENCE FINANCING BROKER works as a Financial Advisor
                                        to support customers with the best financial products 
                                        suitable to their needs. 
                                        Our organization works on its core value “TRUST and LOYALTY”. We’re determined to use our knowledge, experience and, 
                                        intellect to find the best solutions to client’s requirements
                                        </p>
                                    <ul class="social-footer">
                                         <div id="socialF">
                                            <a href="https://www.facebook.com/ExcellenceFinancingBroker/"
                                                    className="facebook social">
                                                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                                            </a>
                                        </div>

                                        <div id="socialF">
                                            <a href="https://www.instagram.com/excellencefinancingbroker/"
                                                    className="instagram social">
                                                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                                            </a>
                                        </div>
                                        <div id="socialF">
                                            <a href="https://web.whatsapp.com/send?phone=+97143412266&text=Hi, I contacted you Through your website."
                                                    className="whatsapp social">
                                                    <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                                            </a>
                                        </div>

                                        <div id="socialF" >
                                            <a href="https://www.linkedin.com/company/excellencefinancingbroker/"
                                                    className="whatsapp social">
                                                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                                            </a>
                                        </div>
                                    </ul>

                                    </div>

                                </div>
                                <div class="ourService">
                                    <h1 class ="Hone">Our Services</h1>
                                    <ul>
                                        <li class="footer-li">Personal Loan</li>
                                        <li class="footer-li">Mortgage</li>
                                        <li class="footer-li">Business Loan</li>
                                        <li class="footer-li">Business Account</li>
                                        <li class="footer-li">Credit Cards</li>
                                    </ul>

                                </div>
                                <div class="ourOffice">
                                <h1 class ="Hone">Our Office</h1>  
                                <ul>                          
                                    <li   class="footer-li" style={{width: "70%"}} >3rd Floor, Al Abbas Building,
                                        Khalid Bin Al Waleed St.,
                                        Dubai, U.A.E.</li>
                                    <p class="footer-li">+971 4341 2266</p>    
                                    <li>
                                        <a href="https://www.google.com/maps/place/Al+Abbas+Building+-+Bank+Street,43832+-+44+B+-+Umm+Hurair+1+-+Dubai+-+United+Arab+Emirates/@25.2547118,55.3048542,18z/data=!4m5!3m4!1s0x3e5f432fb3c8afc1:0x929c433f82b106e3!8m2!3d25.2547846!4d55.3057769">
                                        <img class="mapImage" src={mapImage} alt="Location Map"></img>
                                        </a>
                                    </li> 
                                </ul> 

                                </div>

                            </div>    
                        </footer>  
    )
}

export default Footer;