import { Upload, message, Button, Form, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from "axios";

const FileUpload = () => {

  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'File Uploaded',
      description:
        'File Uploaded Successfully. Please refresh to upload new file.',
    });
  };

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      };

      const normFile = (e) => {
        console.log('Upload event:', e);

        if(e.file.status === "done"){
          console.log('Filename:', e.file.response.filename);
          localStorage.setItem("filename",  e.file.response.filename)
        }
        
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
      };

const email= localStorage.getItem('email')
const cus_name = localStorage.getItem('name')
const cus_email= localStorage.getItem('email')
const file = localStorage.getItem('filename')

 const onFinish= () => {
    axios.post("http://localhost:3002/fileupload/savefile",{email, file, cus_name, cus_email})
     .then(res=> console.log(res.data))
     openNotificationWithIcon('success')  
  }

return (
    <Form
    name="validate_other"
    {...formItemLayout}
    onFinish={onFinish}
  >
    <Form.Item
    name="upload"
    label="Upload"
    valuePropName="fileList"
    getValueFromEvent={normFile}
    
  >
    <Upload method="POST" name="file" action="http://localhost:3002/fileupload/single" listType="picture">
      <Button icon={<UploadOutlined />}>Click to upload</Button>
    </Upload>
  </Form.Item>
  <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
  </Form>


)
}

export default FileUpload;